import React, { useContext, useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"

import Header from "../components/Header"
import Section from "../components/Section"
import Seo from "../components/seo"
import Card from "../components/Card"
import StepCard from "../components/StepCard"
import Carousel from "../components/Carousel"
import StyledSection from "../components/StyledSection"
import CtaBtn from "../components/CtaBtn"
import { ModalContext } from "../components/layout"

import start from "../images/zapis.jpg"
import doctor from "../images/doktor.jpg"
import pkk from "../images/pkk.jpg"
import driver from "../images/jazdy.jpg"
import test from "../images/egzamin.jpg"

const IndexPage = () => {
  const { data, openModal, setUpModalContent, openFormModal } =
    useContext(ModalContext)

  const [today, setToday] = useState(new Date())

  useEffect(() => {
    setToday(new Date())
  }, [])

  const stepCards = [
    {
      title: "Zapisz się na kurs",
      color: "dark",
      image: start,
      context: (
        <>
          <p className="step-card__text">
            Twoim pierwszym krokiem będzie wybór szkoły jazdy, która przygotuje Cię do państwowego egzaminu na prawo jazdy. Taki egzamin składa się z dwóch części, teoretycznej oraz praktycznej. Posiadamy największą liczbę zadowolonych klientów w Tychach. Potwierdzają to{" "}
            <a
              href="https://www.superprawojazdy.pl/osk-adept,9949.htm"
              rel="noreferrer"
              target="_blank"
            >
              opinie naszych adeptów
            </a>
            . Zajmujemy{" "}
            <a
              href="https://www.superprawojazdy.pl/tychy.htm"
              rel="noreferrer"
              target="_blank"
            >
              pierwsze miejsce w rankingach
            </a>{" "}
            szkół jazdy w Tychach. Ty również możesz zostać naszym kursantem i dołączyć do grona zadowolonych klientów.
            <br /> Opłaty za kurs możesz dokonać w ratach 0%. Nasz OSK w Tychach to najlepszy wybór!
          </p>
          <CtaBtn
            _classes="cta-banner__button step-card__action"
            text="Zapisz się teraz"
            action={openFormModal}
          />
        </>
      ),
    },
    {
      title: "Badania lekarskie",
      color: "light",
      image: doctor,
      reverse: true,
      context: (
        <p className="step-card__text">
          Po wybraniu odpowiedniej szkoły jazdy, będziesz musiał wykonać badania
          lekarskie. Jeżeli zdecydujesz się na odbycie kursu w naszej szkole
          jazdy, pomożemy Ci przejść cały ten proces. Skierujemy Cię na badania
          w dogodnym dla Ciebie terminie. Istotnym jest, abyś na badania zabrał
          ze sobą dokument tożsamości (dowód osobisty lub legitymację szkolną)
          oraz nie spożywał słodkich napojów bezpośrednio przed badaniami.
        </p>
      ),
    },
    {
      title: "PKK- Profil Kandydata na Kierowcę",
      color: "dark",
      image: pkk,
      context: (
        <>
          <p className="step-card__text">
            Na tym etapie również nie zostawimy Cię samego. Wszystkie potrzebne dokumenty/wnioski otrzymasz od nas na badaniu lekarskim lub będziesz mógł pobrać je poniżej.<br />
            Po otrzymaniu zgody lekarskiej na przystąpienie do kursu na prawo
            jazdy, będziesz musiał wyrobić PKK. Profil Kandydata na Kierowcę
            jest niezbędnym dokumentem, bez którego nie będziesz mógł/mogła
            przystąpić do nauki jazdy. Dokument ten będziesz mógł/mogła wyrobić
            w swoim starostwie odpowiednim do miejsca zamieszkania. W Tychach
            jest to Wydział Komunikacji ({" "}
            <a
              href="https://goo.gl/maps/nTTcVpPyEGfPLEUB9"
              rel="noreferrer"
              target="_blank"
            >
              Tychy ul. Budowlanych 59
            </a>
            ). Do wyrobienia PKK niezbędne będą:
          </p>
          <ul className="step-card__text step-card__list">
            <li>zdjęcie (takie samo jak do dowodu osobistego),</li>
            <li>orzeczenie lekarskie,</li>
            <li>zgoda rodziców, jeśli jesteś osobą niepełnoletnią,</li>
            <li>wniosek.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Rozpocznij kurs",
      color: "light",
      image: driver,
      reverse: true,
      context: (
        <p className="step-card__text">
          Po dostarczeniu do szkoły jazdy numeru PKK (wystarczy wysłać wiadomość sms z numerem) i odbyciu szkolenia teoretycznego (wykłady lub samodzielna nauka w domu) będziesz mógł/mogła rozpocząć jazdy. Po zakończeniu jazd czekają Cię egzaminy – wewnętrzny teoretyczny oraz praktyczny, który odbywa się u nas w ośrodku.
        </p>
      ),
    },
    {
      title: "Zdaj egzamin państwowy",
      color: "dark",
      image: test,
      context: (
        <>
          <p className="step-card__text">
            Po zakończeniu szkolenia i zdaniu egzaminów wewnętrznych będziesz gotowy/gotowa, aby przystąpić do egzaminów państwowych w WORD-zie.{" "}
            <br />W pierwszej kolejności musisz zdać egzamin teoretyczny, jeżeli zaliczysz ten egzamin, będziesz mógł/mogła przystąpić do części praktycznej. Kiedy ją zdasz, wyniki natychmiast zostaną wprowadzone do systemu teleinformatycznego przez Twojego egzaminatora. Otrzymanego dokumentu nie musisz nigdzie zanosić. Teraz pozostało czekać na możliwość odbioru prawo jazdy w starostwie w Wydziale Komunikacji, a status dokumentu możesz śledzić na stronie:
            <a
              rel="noreferrer"
              target="_blank"
              href="https://info-car.pl/new/prawo-jazdy/sprawdz-status-prawa-jazdy"
            >
              {" "}
              info-car.pl
            </a>
            . (
            <Link to="/polityka/#pamietaj">
              Początkujący kierowco, pamiętaj!
            </Link>
            )
          </p>
        </>
      ),
    },
  ]

  const steps = stepCards.map((item, index) => (
    <StepCard
      key={index}
      step={`krok ${index + 1}`}
      title={item.title}
      color={item.color}
      image={item.image}
      reverse={item.reverse}
    >
      {item.context}
    </StepCard>
  ))

  const dates =
    data &&
    data.registrations.map(i => ({
      id: i.id,
      date: new Date(i.date),
      isFull: i.isFull,
    }))

  const checkDate = (day, month, year) => {
    if (
      dates.find(item => {
        const checkDay = item.date.getDate()
        const checkMonth = item.date.getMonth()
        const checkYear = item.date.getFullYear()

        return (
          day === checkDay &&
          month === checkMonth &&
          year === checkYear &&
          item.isFull
        )
      })
    )
      return "full"

    if (
      dates.find(item => {
        const checkDay = item.date.getDate()
        const checkMonth = item.date.getMonth()
        const checkYear = item.date.getFullYear()

        return day === checkDay && month === checkMonth && year === checkYear
      })
    )
      return "available"
  }

  const getDate = (day, month, year) => {
    const { id } = dates.find(item => {
      const checkDay = item.date.getDate()
      const checkMonth = item.date.getMonth()
      const checkYear = item.date.getFullYear()

      if (day === checkDay && month === checkMonth && year === checkYear)
        return item
      return null
    })

    return data.registrations.find(item => item.id === id)
  }

  return (
    <>
      <Seo title="Szkoła jazdy - kurs prawa jazdy kat. B" />
      <Header
        title={
          <>
            <span className="d-block header__title--primary">
              Szkoła nauki jazdy
            </span>
            Numer jeden w Tychach{" "}
          </>
        }
      >
        <div className="header__hero--mobile">
          <StaticImage
            className="header__hero header__hero--start"
            src="../images/driver-xs.jpg"
            alt="nauka jazdy"
          />
        </div>
        <div className="header__hero--desktop">
          <StaticImage
            className="header__hero header__hero--start"
            src="../images/driver.jpg"
            alt="nauka jazdy"
          />
        </div>
      </Header>
      <Section title="Jak zdobyć prawo jazdy?">{steps}</Section>
      <StyledSection>
        <h2 className="styled-section__title">
          Kurs na prawo jazdy –<br /> Ośrodek Szkolenia Kierowców
          <br /> w Tychach
        </h2>
        <p className="styled-section__text">
          Rozpoczęcie kursu to pierwszy krok do niezależności, ale także ogromna odpowiedzialność. Dla wielu jest to jeden z ważniejszych momentów w życiu, dlatego warto, aby ten czas był wartościowy i dobrze zapamiętany.
        </p>
        <p className="styled-section__text">
          Nasz ośrodek szkoleniowy ma wieloletnie doświadczenie w przeprowadzaniu kursu na prawo jazdy kategorii B. Nasi adepci są skrupulatnie przygotowywani do egzaminów państwowych, co potwierdzają opinie byłych kursantów i wieloletni najwyższy próg zdawalności. Wykwalifikowani instruktorzy naszego Ośrodka Szkolenia Kierowców w Tychach z pasją przekażą Ci nie tylko teoretyczną wiedzę, ale także praktyczne umiejętności prowadzenia pojazdu, dzięki czemu kurs prawa jazdy będzie łatwy i przyjemny!
        </p>
        <h2 className="styled-section__title">
          Zastanawiasz się, jaka jest cena za kurs na prawo jazdy kat. B w Tychach?
        </h2>
        <p className="styled-section__text">
          Nie martw się – posiadamy szeroką ofertę usług, a także konkurencyjne ceny. Bez wątpienia znajdziesz coś dla siebie. Z nami możesz nawet przejść naukę teorii online! Jeżeli zależy Ci na jak najszybszym przystąpieniu do egzaminu państwowego, oferujemy kurs przyspieszony. Jeżeli chcesz dowiedzieć się więcej o naszych usługach,{" "}
          <Link to="/#cennik">przejdź do sekcji "Cennik"</Link>, w której szczegółowo opisaliśmy poszczególne oferty.
        </p>
      </StyledSection>
      <Section title="Cennik" _id="cennik">
        {data && (
          <Carousel>
            {data?.offers.sort(({order: orderA}, {order: orderB}) => orderA - orderB).map(item => (
              <Card key={item.id} {...item} />
            ))}
          </Carousel>
        )}
      </Section>
      <Section title="Terminarz" _id="terminarz">
        <Calendar
          prev2Label={false}
          next2Label={false}
          defaultActiveStartDat={today}
          onChange={e => {
            const day = e.getDate()
            const month = e.getMonth()
            const year = e.getFullYear()

            if (checkDate(day, month, year) === "full") {
              setUpModalContent({
                title: "Niestesty w tym terminie nie ma już wolnych miejsc :(",
                subtitle: "",
                context: (
                  <p>
                    Prosimy o wybór innego, dostępnego terminu. Wszystkie
                    dostępne terminy, można znaleźć w naszym terminarzu lub
                    bezpośrednio w formularzu zgłoszeniowym.
                  </p>
                ),
                postScript: "Termin",
                postScriptAmount: `${day}.${month}.${year} r.`,
              })
              return openModal()
            }

            if (checkDate(day, month, year) === "available")
              return openFormModal(getDate(day, month, year))
          }}
          tileClassName={e => {
            if (!dates) return false
            const day = e.date.getDate()
            const month = e.date.getMonth()
            const year = e.date.getFullYear()

            if (checkDate(day, month, year) === "full")
              return "react-calendar__tile--full"

            if (checkDate(day, month, year) === "available")
              return "react-calendar__tile--available"
          }}
        />

        <div className="legend__container">
          <div className="legend d-flex ">
            <div className="legend__color legend__color--primary"></div>
            <p className="legend__description">
              - Dzień rozpoczęcia kursu (dostępne miejsca).
            </p>
          </div>
          <div className="legend d-flex">
            <div className="legend__color legend__color--danger"></div>
            <p className="legend__description">
              - Dzień rozpoczęcia kursu (brak miejsc).
            </p>
          </div>
        </div>
      </Section>
    </>
  )
}

export default IndexPage
